.custom-btn {
    display: flex !important;
    align-items: center;
    font-size: 20px !important;
    color: #544343 !important;
}
.section-wrapper {
    background: #fff;
    padding: 0 0 100px;
    height: calc(100vh - 162px);
    position: relative;
}
.section-wrapper .custom-list {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    box-shadow: 0 6px 2px 0 rgba(0,0,0,.07);
    list-style: none;
    overflow-y: auto;
    height: 100px;
    margin-bottom: 0;
    position: relative;
    z-index: 2;
}
.section-wrapper .custom-list .list-item:hover {
    background-image: linear-gradient(0deg,rgba(255,0,149,.25),rgba(255,0,149,0));
    color: #fb990e;
}
.section-wrapper .custom-list .active {
    background-image: linear-gradient(0deg,rgba(255,0,149,.25),rgba(255,0,149,0));
    color: #fb990e;
}
.section-wrapper .custom-list .list-item {
    font-weight: 800;
    font-size: 13px;
    text-transform: uppercase;
    height: 100%;
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 0;
}
.section-wrapper .custom-list .list-item img {
    width: 65px;
    display: block;
    margin: 0 auto;
}
.uploader-section{
    background-image: url(https://tinipix.com/desktop-assets/static/images/design-bg.jpg);
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: auto;
    position: relative;
    z-index: 1;
    padding: 45px 20px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.ant-upload-drag-icon {
    color: #fc0377;
    font-size: 35px;
    display: block;
    width: 100%;
}
.ant-upload-text {
    font-size: 16px;
    font-weight: 500;
    color: #383838;
    margin: 0;
    display: block;
    width: 100%;
}
.ant-upload-hint {
    margin-top: 15px;
    display: block;
    width: 100%;
}

.uploader-section .ant-upload-list {
    display: flex;
    justify-items: center;
}
.uploader-section .ant-upload-select-picture-card {
    border: 4px dashed #b0b0b0 !important;
    padding: 10px;
    width: 255px !important;
    height: 255px !important;
    border-radius: 14px !important;
    margin: 30px !important;
}
.uploader-section .ant-upload-list-picture-card-container {
    border: none !important;
    padding: 0 !important;
    width: 255px !important;
    height: 255px !important;
    position: relative !important;
    overflow: hidden !important;
    -webkit-box-align: center !important;
    align-items: center !important;
    -webkit-box-pack: center !important;
    justify-content: center !important;
    display: -webkit-box !important;
    display: flex;
    cursor: pointer;
    margin: 30px !important;
}
.ant-upload-list-item-thumbnail {
    opacity: 1 !important;
}
.frame0 .ant-upload-list-picture-card-container:before {
    background-image: url("https://tinipix.com/desktop-assets/static/images/frames/white-large.svg");
    border: none;
    padding: 0;
    position: absolute;
    top: -2px;
    left: -6px;
    content: "";
    width: 269px;
    height: 269px;
    margin: 0;
    z-index: 2;
}
.frame2 .ant-upload-list-picture-card-container:before {
    background-image: url("https://tinipix.com/desktop-assets/static/images/frames/white-large.svg");
    border: none;
    padding: 0;
    position: absolute;
    top: -2px;
    left: -6px;
    content: "";
    width: 269px;
    height: 269px;
    margin: 0;
    z-index: 2;
}
.frame1 .ant-upload-list-picture-card-container:before {
    background-image: url("https://tinipix.com/desktop-assets/static/images/frames/black-large.svg");
    border: none;
    padding: 0;
    position: absolute;
    top: -2px;
    left: -6px;
    content: "";
    width: 269px;
    height: 269px;
    margin: 0;
    z-index: 2;
}

.frame3 .ant-upload-list-picture-card-container:before {
    background-image: url("https://tinipix.com/desktop-assets/static/images/frames/black-large.svg");
    border: none;
    padding: 0;
    position: absolute;
    top: -2px;
    left: -6px;
    content: "";
    width: 269px;
    height: 269px;
    margin: 0;
    z-index: 2;
}
.ant-upload-list-item-info {
    background: white;
}
.frame2 .ant-upload-list-item-info{
    height: 269px;
    width: 255px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.frame3 .ant-upload-list-item-info{
    height: 269px;
    width: 255px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.frame2 .ant-upload-list-item-info span{
    height: 168px;
    width: 168px;
}
.frame2 .ant-upload-list-item-info span img{
    height: 100% !important;
    width: 100% !important;
}
.frame3 .ant-upload-list-item-info span{
    height: 168px;
    width: 168px;
}
.frame3 .ant-upload-list-item-info span img{
    height: 100% !important;
    width: 100% !important;
}

.ant-upload-list-item-error {
    border: none !important;
    padding: 0 !important;
}
.uploader-section .ant-upload-list-picture-card-container img {
    width: 255px !important;
    height: 255px !important;
}
.order-btn {
    background-color: #fbd157 !important;
    color: #544343 !important;
    height: 50px !important;
    font-size: 20px !important;
    font-weight: 800 !important;
    cursor: pointer !important;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    border: none !important;
    display: block !important;
}
.ant-upload-list-item {
    border: none !important;
}

.normal-view .ant-drawer-content-wrapper{
    width: 30vw !important;
}

.mobile-view .ant-drawer-content-wrapper{
    width: 100vw !important;
}
.ant-upload-list-item-actions {
    width: 255px;
    height: 255px ;
    display: flex;
    align-items: center;
    justify-content: center;
}
.custom-form .ant-row{
    margin-bottom: 10px;
}